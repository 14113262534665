.Galeria{
    margin-top: 2rem;
    padding: 2rem 3rem;
    .bg{
        margin-left: -10.8rem;
    }
    .titleSection{
        margin-top: -1.7rem;
        padding-bottom: 1.5rem;
    }
    .subtitleSection{
        margin-left: -.6rem;
    }
    .SliderGaleria{
        .item{
            width: 100%;
            height: 400px;
            background-repeat: no-repeat!important;
            background-size: cover!important;
            background-position-x: center!important;
            background-position-y: center!important;
            .textBox {
                position: absolute;
                bottom: .7rem;
                left: .7rem;
                .secondText {
                    color: #fff;
                    font-size: 12px;
                    font-weight: 400;
                }
                .mainText {
                    color: #fff;
                    font-weight: 900;
                    font-size: 22px;
                }
            }
        }
        .owl-nav{            
            position: absolute;
            top: 150px;
            width: 100%;
        }
        .owl-prev{
            background: url("../../dist/images/svg/arrowLeft.svg")!important;
            background-repeat: no-repeat!important;
            background-position-x: center;
            background-position-y: top;
            height: 100px;
            width: 30px;
            float: left;
            margin-left: -50px;
            @media(max-width:1230px){
                margin-left: 20px;
            }
            span {
                display: none;
            }
        }
        .owl-next{
            background: url("../../dist/images/svg/arrowRight.svg")!important;
            background-repeat: no-repeat!important;
            background-position-x: center;
            background-position-y: top;
            height: 100px;
            width: 30px;
            float: right;
            margin-right: -50px;
            @media(max-width:1230px){
                margin-right: 20px;
            }
            span {
                display: none;
            }
        }
    }
}
.lb-data .lb-number {
	display: none!important;
}