
@mixin RoomBG($bgcolor, $color) {
	color: $color;
	background-color: $bgcolor;
}   

@mixin fontFamily {
  font-family: 'Montserrat', sans-serif;
}

@mixin FontBold($weight) {
  font-weight: $weight;
}

@mixin Title() {
  color: $textColor;
  font-size: 36px;
  font-weight: 700;
  @include rwd(tablet) {
    font-size: 24px;
    font-weight: 500;
  }
}

@mixin subTitle() {
  color: $textColor;
  font-size: 22px;
  font-weight: 500;
}

@mixin Text() {
  color: $textColor;
  font-size: 18px;
  font-weight: 300;
  margin: 1rem 0;
  @include rwd(tablet) {
    font-size: 0.8rem;
  }
}




@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

@mixin overlay($bgColor: #000, $bgOpacity: 1){
.overlay{
    background:rgba($bgColor, $bgOpacity);
    position:absolute;
    width:100%;
    height:100%;
    left:0;
    top:0;
}
}

@mixin css3-prefix($prop, $value) {
  -webkit-#{$prop}: #{$value};
  -moz-#{$prop}: #{$value};
  -ms-#{$prop}: #{$value};
  -o-#{$prop}: #{$value};
  #{$prop}: #{$value};
}
/* przykład 
##SCSS 
div {
  @include css3-prefix(transform, scale3d(2.5, 2, 1.5));
}
 ##CSS 
div {
  -webkit-transform: scale3d(2.5, 2, 1.5);
  -moz-transform: scale3d(2.5, 2, 1.5);
  -ms-transform: scale3d(2.5, 2, 1.5);
  -o-transform: scale3d(2.5, 2, 1.5);
  transform: scale3d(2.5, 2, 1.5);
}
*/

/* Vertical Centering SCSS */
@mixin vertical-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
/*
## SCSS 
div {
  @include vertical-center();
}
## CSS 
div {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
*/

/* Retina-Ready Image SCSS */
@mixin retina-image($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
      background-image: url($image);
      background-size: $width $height;
  }
}
/* 
###SCSS
.image {
  background: url("my-image.png") no-repeat;
  @include retina-image("my-image2x.png", 1000px, 500px);
}
###CSS
.image {
  background: url("my-image.png") no-repeat;
}
@media (min--moz-device-pixel-ratio: 1.3), 
(-o-min-device-pixel-ratio: 2.6 / 2), 
(-webkit-min-device-pixel-ratio: 1.3), 
(min-device-pixel-ratio: 1.3), 
(min-resolution: 1.3dppx) {
  .image {
    background-image: url("my-image2x.png");
    background-size: 1000px 500px;
  }
}
 */

/* Arrow with Four Optional Directions SCSS */
@mixin arrow($direction: down, $size: 5px, $color: #555) {
  width: 0;
  height: 0;
  @if ($direction == left) {
     border-top: $size solid transparent;
     border-bottom: $size solid transparent; 
     border-right: $size solid $color;
  }
  @else if ($direction == right) {
     border-top: $size solid transparent;
     border-bottom: $size solid transparent; 
     border-left: $size solid $color;
  }
  @else if ($direction == down) {
     border-left: $size solid transparent;
     border-right: $size solid transparent;
     border-top: $size solid $color;
  }
  @else {
     border-left: $size solid transparent;
     border-right: $size solid transparent;
     border-bottom: $size solid $color;
  }
}
/*
 ###SCSS

// without arguments (default)
div { 
  @include arrow(); 
}

// with custom arguments
div {
  @include arrow(up, 10px, #efefef);
}
###CSS
// without arguments (default)
div {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #555;
}

// with custom arguments
div {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #efefef;
}
*/


/* Example of use is at the bottom of file */

$breakpointsDown: (
  "phone": "(max-width:  360px)",
  "large-phone": "(max-width:  480px)",
  "small-tablet": "(max-width:  600px)",
  "tablet": "(max-width:  768px)",
  "medium-tablet": "(max-width:  991px)",
  "large-tablet": "(min-width: 1024px)",
  "laptop": "(max-width: 1280px)",
  "laptop-h": "(max-width: 1280px)",
  "large-laptop": "(max-width: 1366px)",
  "ultra": "(max-width: 1600px)",
);

$breakpointsUp: (
  "phone": "(min-width:  361px)",
  "large-phone": "(min-width:  481px)",
  "small-tablet": "(min-width:  601px)",
  "tablet": "(min-width:  769px)",
  "medium-tablet": "(min-width:  992px)",
  "large-tablet": "(min-width: 1025px)",
  "laptop": "(min-width: 1281px)",
  "large-laptop": "(min-width: 1367px)",
  "ultra": "(min-width: 1599px)",
);

$mobileFirst: false !default;
@mixin rwd($breakpoint, $breakpointUp: $mobileFirst) {
  @if ($breakpointUp != false) {
    @if map-has-key($breakpointsUp, $breakpoint) {
      @media #{(map-get($breakpointsUp, $breakpoint))} {
        @content;
      }
    } @else {
      @warn 'Unfortunately, not found breakpoint called `#{$breakpoint}`!';
    }
  } @else {
    @if map-has-key($breakpointsDown, $breakpoint) {
      @media #{(map-get($breakpointsDown, $breakpoint))} {
        @content;
      }
    } @else {
      @warn 'Unfortunately, not found breakpoint called `#{$breakpoint}`!';
    }
  }
}

/*
  Example:

    ul {
      margin-bottom: 15px;

      @include rwd(tablet, true) {
        margin-bottom: 30px;
      }
      
      li {
        margin-bottom: 20px;

        @include rwd(tablet) {
          margin-bottom: 10px;
        }
      }
    }

  Result:

    ul {
      margin-bottom: 15px;
    }

    ul li {
      margin-bottom: 20px;
    }

    @media (min-width: 769px) {
      ul {
        margin-bottom: 30px;
      }
    }

    @media (max-width: 768px) {
      ul li {
        margin-bottom: 10px;
      }
    }

*/
