
ul#menu-menu-glowne-pl{
    clear: both;
    margin-right: 0;
    li{
            padding-left: 10px;
    }
    a{
        color: #ffffff;
        text-transform: uppercase;
        font-size: 14px;
        @include rwd(medium-tablet) {
            font-size: 12px;
        }
        @include rwd(large-tablet) {
            font-size: 13px;
        }
        font-weight: 300;
        &:hover{
            @include rwd(medium-tablet,true) {
                -webkit-animation-name: hvr-pulse;
                animation-name: hvr-pulse;
                -webkit-animation-duration: 1s;
                animation-duration: 1s;
                -webkit-animation-timing-function: linear;
                animation-timing-function: linear;
                -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
            }
        }
    }
    &.navbar-nav {
        align-items: center;
    }
}


.navbar-collapse{
    padding: 10px 0;

    .nav-item{
        &:nth-child(1) {
            margin-right: 10px;
        }
        &:nth-child(2) {
            margin-right: 10px;
        }
        &:nth-child(3) {
            margin-right: 10px;
        }
        &:nth-child(4) {
            margin-right: 10px;
        }
        &:nth-child(5) {
            margin-right: 20px;
        }

        &.dropdown{   
                > a{
                    padding-right: 27px !important;
                    background:url('../images/svg/downArrow.svg') no-repeat top 16px right 10px;
                    background-size: 12px;
                    @include rwd(medium-tablet) {
                        
                    }
                }     
        }
            

        .nav-link{
            color:$cBody;
            margin:2px 5px;
            font-size:16px;
            font-weight: 500;
            letter-spacing: .3px;
            display:flex;
            align-items: center;
            padding: 10px 5px 5px 0;

            @media (min-width:1100px){
                padding: 9px 5px 5px 0 !important;
            }

            @include rwd(tablet) {
                color: #111110;
                margin: 0px 0px;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: .3px;
                display: flex;
                align-items: center;
                padding: 5px 0;
            }
                
                &:hover{
                    text-decoration: underline;
                }
            

        }

    

        &.active{
        & > .hvr-border-fade{
                box-shadow: inset 0 0 0 1px #fff, 0 0 1px rgba(0, 0, 0, 0);
            }
        }

    }

    .dropdown{
        

        &:hover{
            -webkit-animation-name: unset;
            animation-name: unset;
            -webkit-animation-duration: unset;
            animation-duration: unset;
            -webkit-animation-timing-function: unset;
            animation-timing-function: unset;
            -webkit-animation-iteration-count: unset;
            animation-iteration-count: unset;
            
            @media(min-width:992px){
                .dropdown-menu{
                    display: block !important;   
                }
            }
        }

        .dropdown-menu{
            background-color:transparent;  
            border:0 !important; 
            border-radius: 5px;
            min-width: 250px;
            
            //max
            @media(max-width:992px){
                padding-top: 0;
                padding-left: 7px;
            }


            .nav-link{
                border-bottom:1px solid $cGreen !important;                
                transition:.2s;
                &.hvr-border-fade{
                    border: 0 !important;
                    border-bottom:1px solid $cGreen !important;   

                    &:hover{
                        border: 0 !important;
                        border-bottom:1px solid #666 !important;   
                        box-shadow: none !important;
                    }
                }
            }
        }
    }
}