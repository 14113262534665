.modal{
    $root: &;
	top: 3rem!important;
    padding: 1rem 0 0 1rem;
    @include rwd(large-phone) {
        padding: 1rem;
    }        
    &-content{
        #{$root}-footer{
            border-top: 0;
            margin-top: 1rem;        
        }
        
        #{$root}-header{
            background: #000;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            #{$root}-title{
                text-align: center;
                font-size: 3rem;
                font-weight: 700;
                width: 100%;
            }
            #{$root}-logo{
                img {
                    max-width: 100px;
                }
            }
            .close {
                font-size: 2.5rem;
                font-weight: 700;
                line-height: 1;
                color: #fff;
                text-shadow: unset;
            }
        }
    }
    &-dialog {
        max-width: 900px;
        margin: 1.75rem auto;
        width: 100%;
    }
}