@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
html {
  box-sizing: border-box;
  font-size: 16px;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* przykład 
##SCSS 
div {
  @include css3-prefix(transform, scale3d(2.5, 2, 1.5));
}
 ##CSS 
div {
  -webkit-transform: scale3d(2.5, 2, 1.5);
  -moz-transform: scale3d(2.5, 2, 1.5);
  -ms-transform: scale3d(2.5, 2, 1.5);
  -o-transform: scale3d(2.5, 2, 1.5);
  transform: scale3d(2.5, 2, 1.5);
}
*/
/* Vertical Centering SCSS */
/*
## SCSS 
div {
  @include vertical-center();
}
## CSS 
div {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
*/
/* Retina-Ready Image SCSS */
/* 
###SCSS
.image {
  background: url("my-image.png") no-repeat;
  @include retina-image("my-image2x.png", 1000px, 500px);
}
###CSS
.image {
  background: url("my-image.png") no-repeat;
}
@media (min--moz-device-pixel-ratio: 1.3), 
(-o-min-device-pixel-ratio: 2.6 / 2), 
(-webkit-min-device-pixel-ratio: 1.3), 
(min-device-pixel-ratio: 1.3), 
(min-resolution: 1.3dppx) {
  .image {
    background-image: url("my-image2x.png");
    background-size: 1000px 500px;
  }
}
 */
/* Arrow with Four Optional Directions SCSS */
/*
 ###SCSS

// without arguments (default)
div { 
  @include arrow(); 
}

// with custom arguments
div {
  @include arrow(up, 10px, #efefef);
}
###CSS
// without arguments (default)
div {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #555;
}

// with custom arguments
div {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #efefef;
}
*/
/* Example of use is at the bottom of file */
/*
  Example:

    ul {
      margin-bottom: 15px;

      @include rwd(tablet, true) {
        margin-bottom: 30px;
      }
      
      li {
        margin-bottom: 20px;

        @include rwd(tablet) {
          margin-bottom: 10px;
        }
      }
    }

  Result:

    ul {
      margin-bottom: 15px;
    }

    ul li {
      margin-bottom: 20px;
    }

    @media (min-width: 769px) {
      ul {
        margin-bottom: 30px;
      }
    }

    @media (max-width: 768px) {
      ul li {
        margin-bottom: 10px;
      }
    }

*/
body {
  color: #231F20;
}

.mainContainer {
  max-width: 1122px;
  margin: 0 auto;
}

@media (min-width: 992px) and (max-width: 1279px) {
  .mainContainer {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .mainContainer {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 1025px) {
  .container-fluid {
    padding-right: unset;
    padding-left: unset;
  }
}

a {
  color: #231F20;
}

/* Pulse */
@-webkit-keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

/* BACKGROUND */
/* COLORS */
/*  others */
p {
  font-size: 14px;
}

@media (max-width: 1279px) {
  p {
    font-size: 12px;
  }
}

.slide {
  width: 100%;
  height: 100%;
  position: relative;
}

.psuedo-background-img {
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 480px) {
  .psuedo-background-img {
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.titleSection {
  padding-bottom: 2rem;
  color: #111110;
  line-height: 44px;
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 700;
}

@media (max-width: 1366px) {
  .titleSection {
    font-size: 40px;
  }
}

@media (max-width: 1280px) {
  .titleSection {
    font-size: 40px;
  }
}

@media (min-width: 1024px) {
  .titleSection {
    font-size: 40px;
  }
}

@media (max-width: 991px) {
  .titleSection {
    font-size: 32px;
  }
}

@media (max-width: 768px) {
  .titleSection {
    font-size: 32px;
  }
}

@media (max-width: 600px) {
  .titleSection {
    font-size: 32px;
  }
}

@media (max-width: 480px) {
  .titleSection {
    font-size: 30px;
  }
}

@media (max-width: 360px) {
  .titleSection {
    font-size: 28px;
  }
}

.titleSection strong, .titleSection--bold {
  font-weight: 500;
}

.titleSection--small {
  font-size: 26px;
}

@media (max-width: 1366px) {
  .titleSection--small {
    font-size: 26px;
  }
}

@media (max-width: 1280px) {
  .titleSection--small {
    font-size: 26px;
  }
}

@media (max-width: 1280px) {
  .titleSection--small {
    font-size: 22px;
  }
}

@media (min-width: 1024px) {
  .titleSection--small {
    font-size: 22px;
  }
}

@media (max-width: 991px) {
  .titleSection--small {
    font-size: 22px;
  }
}

@media (max-width: 768px) {
  .titleSection--small {
    font-size: 22px;
  }
}

@media (max-width: 600px) {
  .titleSection--small {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .titleSection--small {
    font-size: 18px;
  }
}

@media (max-width: 360px) {
  .titleSection--small {
    font-size: 16px;
  }
}

.titleSection--xsmall {
  font-size: 20px;
}

@media (max-width: 480px) {
  .titleSection--xsmall {
    font-size: 18px;
  }
}

@media (max-width: 360px) {
  .titleSection--xsmall {
    font-size: 16px;
  }
}

.subtitleSection {
  padding-bottom: 0;
  color: #161616;
  font-size: 22px;
  font-weight: 400;
  line-height: 27px;
}

@media (max-width: 1366px) {
  .subtitleSection {
    font-size: 22px;
  }
}

@media (max-width: 1280px) {
  .subtitleSection {
    font-size: 22px;
  }
}

@media (max-width: 1280px) {
  .subtitleSection {
    font-size: 20px;
  }
}

@media (min-width: 1024px) {
  .subtitleSection {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .subtitleSection {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .subtitleSection {
    font-size: 20px;
  }
}

@media (max-width: 600px) {
  .subtitleSection {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .subtitleSection {
    font-size: 18px;
  }
}

@media (max-width: 360px) {
  .subtitleSection {
    font-size: 18px;
  }
}

.subtitleSection strong, .subtitleSection--bold {
  font-weight: 500;
}

.subtitleSection--small {
  font-size: 22px;
}

@media (max-width: 1366px) {
  .subtitleSection--small {
    font-size: 22px;
  }
}

@media (max-width: 1280px) {
  .subtitleSection--small {
    font-size: 22px;
  }
}

@media (max-width: 1280px) {
  .subtitleSection--small {
    font-size: 20px;
  }
}

@media (min-width: 1024px) {
  .subtitleSection--small {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .subtitleSection--small {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .subtitleSection--small {
    font-size: 20px;
  }
}

@media (max-width: 600px) {
  .subtitleSection--small {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .subtitleSection--small {
    font-size: 18px;
  }
}

@media (max-width: 360px) {
  .subtitleSection--small {
    font-size: 16px;
  }
}

.subtitleSection--xsmall {
  font-size: 16px;
}

@media (max-width: 480px) {
  .subtitleSection--xsmall {
    font-size: 14px;
  }
}

@media (max-width: 360px) {
  .subtitleSection--xsmall {
    font-size: 12px;
  }
}

.novisible {
  display: none !important;
}

.showvisible {
  display: block !important;
  padding: 0 !important;
}

.centerOfScreen {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* Yep! */
  width: 48%;
  height: 59%;
}

ul {
  list-style: url("../../dist/images/svg/ptaszek_ok.svg");
  font-size: 14px;
  margin: 0 2rem;
}

ul li {
  margin: .5rem 0;
  padding-left: .4rem;
}

.icoTxt {
  color: #231F20;
  font-size: 12px;
}

body {
  font-family: 'Montserrat', sans-serif;
}

.btn {
  border: 1px solid #231F20;
  border-radius: 30px;
  background-color: #FFFFFF;
  margin: 0 auto;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #231F20;
}

.btn a {
  color: #231F20;
  font-size: 12px;
  padding: 1rem 1.7rem;
  font-weight: 700;
}

.btn:hover {
  color: #FFFFFF;
  border-color: #FFFFFF;
  background: #231F20;
}

.btn:hover a {
  color: #FFFFFF;
  text-decoration: none;
}

.btn--reverse {
  border: 1px solid #FFFFFF;
  border-radius: 30px;
  background-color: #231F20;
  color: #FFFFFF;
  margin: 0 auto;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.btn--reverse:hover {
  color: #231F20 !important;
  border-color: #231F20 !important;
  background: #231F20 !important;
}

.btn--reverse a {
  color: #fff;
}

.hvr-radial-out:active, .hvr-radial-out:focus, .hvr-radial-out:hover {
  color: #231F20;
  border-color: #231F20;
  background-color: #fff;
}

.back-to-top {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
}

.back-to-top img {
  height: 50px;
  width: 40px;
  transform: rotate(180deg);
}

.burger {
  width: 45px;
  height: 30px;
  display: inline-block;
  margin-right: 10px;
  transition: .3s;
  margin-top: -11px;
  background-color: transparent;
  border: 0;
}

.burger:hover, .burger:active, .burger:focus {
  outline: 0;
}

@media (min-width: 992px) {
  .burger {
    display: none;
  }
}

.burger span {
  display: block;
  width: 25px;
  height: 2px;
  background-color: #fff;
  margin-top: 6px;
  transition: .3s;
}

.burger span:nth-child(2) {
  transition: .1s !important;
}

.burger.active {
  /* margin-top:-20px; 
        margin-bottom: 2rem;*/
}

.burger.active span:nth-child(1) {
  transform: rotate(45deg);
  margin-top: 13px;
}

.burger.active span:nth-child(2) {
  opacity: 0;
}

.burger.active span:nth-child(3) {
  transform: rotate(-45deg);
  margin-top: -10px;
}

#cookie-msg {
  padding: 12px !important;
  background-color: #000000;
  z-index: 100;
}

#cookie-msg .msg {
  font-size: 12px;
}

@media (min-width: 576px) {
  #cookie-msg .msg {
    font-size: 14px;
  }
}

#cookie-msg a {
  color: #231F20 !important;
}

#cookie-msg .btn-aceptar {
  background-color: #000000;
  margin-left: 13px;
  font-size: 13px !important;
  color: #231F20 !important;
  margin: 2px;
  display: inline-block;
}

.cta__baner {
  padding: 3rem 0;
  margin-bottom: 5rem;
  margin-top: 5rem;
  background: url("../../dist/images/tlo_pod_baner.png");
  background-repeat: no-repeat;
}

.cta__baner--white {
  background: none;
}

.cta__baner--white__left {
  color: #111110;
}

.cta__baner--white__left__subtitle {
  color: #111110;
}

@media (max-width: 480px) {
  .cta__baner {
    background-position-x: 50%;
    margin: 2rem 0 0 0;
  }
}

.cta__baner__left {
  color: #FFFFFF;
}

.cta__baner__left__title {
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

@media (max-width: 480px) {
  .cta__baner__left__title {
    font-size: 26px;
  }
}

.cta__baner__left__subtitle {
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
}

.cta__baner__left__subtitle span {
  font-size: 40px;
  font-weight: 700;
}

@media (max-width: 480px) {
  .cta__baner__left__subtitle {
    font-size: 16px;
  }
}

.cta__button--green {
  display: block;
  width: 299px;
  height: 55px;
  border: 1px solid #146505;
  border-radius: 30px;
  background-color: #ffffff;
  margin: 0 auto;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.cta__button a {
  color: #231F20;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.cta__button a:hover {
  color: #FFFFFF !important;
  text-decoration: none;
}

.footer {
  background-color: #231F20;
  color: #FFFFFF;
  padding: 3rem 0;
}

.footer__data {
  color: #FFFFFF;
  font-size: 16px;
}

@media (max-width: 768px) {
  .footer__data {
    text-align: center;
  }
}

.footer__data__logo {
  margin: 2.5rem 0;
}

@media (max-width: 480px) {
  .footer__data__logo img {
    width: 150px;
  }
}

.footer__data__smIcons a {
  margin-right: .2rem;
}

.footer__data__address {
  margin: 2.5rem 0;
}

.footer__menu {
  text-align: center;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

@media (max-width: 480px) {
  .footer__menu {
    border: 0;
  }
}

.footer__menu ul.footer__Top__navFooter {
  list-style: none;
  margin: 0;
  margin-top: 2rem;
  padding: 0;
  border: 0px solid #fff;
}

@media (max-width: 480px) {
  .footer__menu ul.footer__Top__navFooter {
    margin: 2rem 0;
  }
}

.footer__menu ul.footer__Top__navFooter a {
  font-size: 12px;
  color: #FFFFFF;
}

.footer__menu ul.footer__Top__navFooter li {
  margin: 0;
  padding: 0;
  line-height: unset;
  text-align: center;
}

@media (max-width: 480px) {
  .footer__menu ul.footer__Top__navFooter li {
    line-height: 12px;
  }
}

@media (max-width: 480px) {
  .footer__menu {
    display: none;
  }
}

.footer__logo img {
  width: 100px;
}

.footer__ico {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.footer__ico p.ico {
  margin: 1rem 0 1rem 2rem;
}

.footer__ico p.ico a.ico {
  display: flex;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

@media (max-width: 480px) {
  .footer__ico p.ico a.ico {
    font-size: 14px;
  }
}

.footer__ico p.ico img.ico {
  margin-right: .8rem;
}

@media (max-width: 480px) {
  .footer__ico p.ico img.ico {
    width: 30px;
  }
}

.footer__ico p.ico span {
  display: flow-root;
}

.footer__ico p.ico span.label {
  font-size: 10px;
  text-transform: uppercase;
}

.AfterFooter {
  background-color: #fff;
}

.AfterFooter__copyBox {
  padding: 10px 0;
  font-size: 14px;
}

@media (max-width: 768px) {
  .AfterFooter__copyBox {
    font-size: 12px;
  }
}

.AfterFooter__copyBox__Model {
  text-align: left;
}

@media (max-width: 600px) {
  .AfterFooter__copyBox__Model {
    text-align: center;
  }
}

.AfterFooter__copyBox__Anronet {
  text-align: right;
}

@media (max-width: 600px) {
  .AfterFooter__copyBox__Anronet {
    text-align: center;
  }
}

.Galeria {
  margin-top: 2rem;
  padding: 2rem 3rem;
}

.Galeria .bg {
  margin-left: -10.8rem;
}

.Galeria .titleSection {
  margin-top: -1.7rem;
  padding-bottom: 1.5rem;
}

.Galeria .subtitleSection {
  margin-left: -.6rem;
}

.Galeria .SliderGaleria .item {
  width: 100%;
  height: 400px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position-x: center !important;
  background-position-y: center !important;
}

.Galeria .SliderGaleria .item .textBox {
  position: absolute;
  bottom: .7rem;
  left: .7rem;
}

.Galeria .SliderGaleria .item .textBox .secondText {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
}

.Galeria .SliderGaleria .item .textBox .mainText {
  color: #fff;
  font-weight: 900;
  font-size: 22px;
}

.Galeria .SliderGaleria .owl-nav {
  position: absolute;
  top: 150px;
  width: 100%;
}

.Galeria .SliderGaleria .owl-prev {
  background: url("../../dist/images/svg/arrowLeft.svg") !important;
  background-repeat: no-repeat !important;
  background-position-x: center;
  background-position-y: top;
  height: 100px;
  width: 30px;
  float: left;
  margin-left: -50px;
}

@media (max-width: 1230px) {
  .Galeria .SliderGaleria .owl-prev {
    margin-left: 20px;
  }
}

.Galeria .SliderGaleria .owl-prev span {
  display: none;
}

.Galeria .SliderGaleria .owl-next {
  background: url("../../dist/images/svg/arrowRight.svg") !important;
  background-repeat: no-repeat !important;
  background-position-x: center;
  background-position-y: top;
  height: 100px;
  width: 30px;
  float: right;
  margin-right: -50px;
}

@media (max-width: 1230px) {
  .Galeria .SliderGaleria .owl-next {
    margin-right: 20px;
  }
}

.Galeria .SliderGaleria .owl-next span {
  display: none;
}

.lb-data .lb-number {
  display: none !important;
}

.header {
  margin: 0 auto -186px auto;
  z-index: 2;
  position: relative;
}

@media (max-width: 992px) {
  .header .navbar {
    background: #000;
    margin-bottom: 11rem;
  }
}

@media (max-width: 768px) {
  .header__menu ul li {
    margin: .2rem 0;
    padding-left: .4rem;
  }
}

.header__menu__top {
  display: flex;
  color: #BCBCBC;
  float: right;
}

.header__icoPhone, .header__icoMail {
  margin: 0 1rem;
}

@media (max-width: 768px) {
  .header__icoPhone, .header__icoMail {
    display: none;
  }
}

.header__icoPhone a.ico, .header__icoMail a.ico {
  color: #BCBCBC;
  font-size: 16px;
  font-weight: 600;
}

.header__icoPhone img.ico, .header__icoMail img.ico {
  margin-right: .8rem;
  width: 25px;
}

@media (max-width: 768px) {
  .header__icoPhone img.ico, .header__icoMail img.ico {
    width: 25px;
  }
}

.header__icoPhone.nofixed, .header__icoMail.nofixed {
  display: block;
}

.header__icoPhone.fixed, .header__icoMail.fixed {
  display: none;
}

@media (min-width: 992px) {
  .header__icoPhone__mobile {
    display: none;
  }
}

.header__icoPhone__mobile a.ico {
  color: #BCBCBC;
  font-size: 16px;
  font-weight: 600;
}

.header__icoPhone__mobile img.ico {
  margin-right: .8rem;
  width: 25px;
}

.header__logo {
  width: 160px;
  height: auto;
}

@media (max-width: 992px) {
  .header__logo {
    width: 140px;
    height: auto;
  }
}

@media (max-width: 768px) {
  .header__logo {
    width: 110px;
    height: auto;
  }
}

@media (max-width: 480px) {
  .header__logo {
    width: 80px;
    height: auto;
  }
}

@media (max-width: 360px) {
  .header__logo {
    width: 70px;
    height: auto;
  }
}

.header #navbarNavDropdown {
  justify-content: center;
  flex-direction: row-reverse;
}

.header--fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #000;
  width: 100%;
  max-width: unset;
}

.header--fixed #navbarNavDropdown {
  flex-direction: unset;
}

.header--fixed .header__logo {
  height: 35px;
  width: auto;
}

.header--fixed .header__icoPhone.nofixed {
  display: none;
}

@media (max-width: 768px) {
  .header--fixed .header__icoPhone.nofixed {
    display: none !important;
  }
}

.header--fixed .header__icoPhone.fixed {
  display: block;
}

.header--fixed .header__icoPhone,
.header--fixed .header__icoMail {
  margin: 0;
}

.header--fixed .header__icoPhone a.ico,
.header--fixed .header__icoMail a.ico {
  color: #bcbcbc;
  font-size: 12px;
  font-weight: 500;
  margin-left: 2rem;
}

.header--fixed .header__icoPhone img.ico,
.header--fixed .header__icoMail img.ico {
  margin-right: .8rem;
  height: 25px;
}

.header--fixed ul#menu-main-manu a {
  color: #339a99;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
}

.header--fixed .header__calltoUs .svg img {
  width: 20px;
  height: 20px;
}

.header--fixed .header__calltoUs a {
  color: #339a99;
  font-size: 22px;
  margin-left: .4rem;
}

.header--fixed .navbar {
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
  max-width: 1122px;
}

@media (max-width: 768px) {
  .header--fixed .navbar {
    margin: 0 auto;
    padding: 10px 10% 0;
  }
}

@media (max-width: 480px) {
  .header--fixed .navbar {
    background: #000;
  }
}

.header--fixed .dropdown-menu {
  margin-top: 0 !important;
}

.NaszeRealizacje {
  padding-bottom: .9rem;
  background-color: #231F20;
  box-shadow: 0px 0px 20px #000000B3;
}

.NaszeRealizacje__container {
  max-width: 1280px;
  margin: 0 auto;
}

.NaszeRealizacje__container a {
  color: #ffffff;
}

.NaszeRealizacje__container a:hover {
  text-decoration: underline;
  color: #fff;
}

.NaszeRealizacje__textBox {
  padding: 0 3rem 2rem;
}

.NaszeRealizacje__textBox .bg {
  margin-left: -.6rem;
}

.NaszeRealizacje__textBox .titleSection {
  font-size: 40px;
  color: #fff;
  font-weight: 700;
  margin-top: -1.7rem;
  padding-bottom: 1rem;
}

@media (max-width: 480px) {
  .NaszeRealizacje__textBox .titleSection {
    font-size: 30px;
  }
}

.NaszeRealizacje__textBox .subtitleSection {
  color: #fff;
}

.NaszeRealizacje__textBox .text {
  color: #fff;
  font-size: 14px;
  line-height: 26px;
  font-weight: 300;
}

.NaszeRealizacje__textBox .text .modal {
  color: #212529;
}

.NaszeRealizacje .btn {
  margin: 1rem 0 0 0;
  top: 0;
  transform: none;
  border-color: #FFFFFF !important;
  background: unset;
}

.NaszeRealizacje .btn a {
  color: #ffffff;
}

.NaszeRealizacje .btn a:hover {
  color: #231F20 !important;
  text-decoration: none;
}

.NaszeRealizacje .btn:hover {
  background-color: #ffffff;
  border-color: #FFFFFF !important;
}

.NaszeRealizacje .SliderRealizacje .item {
  width: 100%;
  height: 400px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position-x: center !important;
  background-position-y: center !important;
}

.NaszeRealizacje .SliderRealizacje .item .textBox {
  position: absolute;
  bottom: .7rem;
  left: .7rem;
}

.NaszeRealizacje .SliderRealizacje .item .textBox .secondText {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
}

.NaszeRealizacje .SliderRealizacje .item .textBox .mainText {
  color: #fff;
  font-weight: 900;
  font-size: 22px;
}

.mainSlider {
  background-color: #000;
  padding-top: 0;
  padding-bottom: 0;
  height: 100vh;
  margin-bottom: 30px;
}

@media (max-width: 480px) {
  .mainSlider {
    min-height: 300px;
  }
}

.mainSlider .item {
  height: 100vh;
  min-height: 300px;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
}

.mainSlider .item .textBox {
  position: absolute;
  width: 100%;
  top: 40vh;
}

@media (max-width: 480px) {
  .mainSlider .item .textBox {
    top: 30vh;
  }
}

.mainSlider .item .textBox__container {
  background-color: unset;
  padding: 3rem;
  margin: 0 auto;
  width: 50%;
  border: 1px solid #ffffff;
}

@media (max-width: 480px) {
  .mainSlider .item .textBox__container {
    padding: .5rem;
    width: 70%;
  }
}

.mainSlider .item .textBox__container .mainText {
  color: #fff;
  font-size: 45px;
  font-weight: 900;
  line-height: 4rem;
}

@media (max-width: 1025px) {
  .mainSlider .item .textBox__container .mainText {
    font-size: 38px;
    font-weight: 700;
    line-height: 32px;
  }
}

@media (max-width: 768px) {
  .mainSlider .item .textBox__container .mainText {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
  }
}

.mainSlider .item .textBox__container .secondText {
  color: #FFFFFF;
  font-size: 40px;
  font-weight: 200;
}

@media (max-width: 1025px) {
  .mainSlider .item .textBox__container .secondText {
    font-size: 32px;
  }
}

@media (max-width: 768px) {
  .mainSlider .item .textBox__container .secondText {
    font-size: 20px;
  }
}

.mainSlider .item .textBox__container .btnBox {
  margin-top: 3rem;
}

.mainSlider .item .textBox__container .button--green {
  display: block;
  width: 240px;
  height: 50px;
  border: 1px solid #146505;
  border-radius: 30px;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: 480px) {
  .mainSlider .item .textBox__container .button--green {
    width: 200px;
    height: 40px;
  }
}

.mainSlider .item .textBox__container .button--green a {
  padding: 5px 0 0 0;
  display: block;
  font-size: 20px;
  text-transform: uppercase;
}

@media (max-width: 480px) {
  .mainSlider .item .textBox__container .button--green a {
    padding: 2px 0 0 0;
    font-size: 16px;
  }
}

.mainSlider .owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 0;
  z-index: 5;
  position: relative;
  bottom: 3rem;
}

.mainSlider .owl-carousel .owl-item img {
  opacity: .3;
}

#map {
  width: 100%;
}

.modal {
  top: 3rem !important;
  padding: 1rem 0 0 1rem;
}

@media (max-width: 480px) {
  .modal {
    padding: 1rem;
  }
}

.modal-content .modal-footer {
  border-top: 0;
  margin-top: 1rem;
}

.modal-content .modal-header {
  background: #000;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.modal-content .modal-header .modal-title {
  text-align: center;
  font-size: 3rem;
  font-weight: 700;
  width: 100%;
}

.modal-content .modal-header .modal-logo img {
  max-width: 100px;
}

.modal-content .modal-header .close {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-shadow: unset;
}

.modal-dialog {
  max-width: 900px;
  margin: 1.75rem auto;
  width: 100%;
}

.EtapyRealizacji {
  margin-top: 0rem;
  background-color: #fff;
}

@media (max-width: 480px) {
  .EtapyRealizacji {
    margin: 0;
  }
}

.EtapyRealizacji__icons {
  display: flex;
  align-items: baseline;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
}

.EtapyRealizacji__icons .icoBox {
  text-align: center;
  width: 20%;
  padding: 0 10px;
}

@media (max-width: 480px) {
  .EtapyRealizacji__icons .icoBox {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .EtapyRealizacji__icons .icoBox {
    margin: 0 1rem 2rem;
  }
}

.EtapyRealizacji__icons .icoBox .txt {
  color: #231F20;
  font-size: 16px;
  line-height: 44px;
  font-weight: 700;
}

.EtapyRealizacji__icons .icoBox .txtSmall {
  color: #727272;
  font-size: 14px;
}

.EtapyRealizacji__icons .icoBox .imgico::after {
  background: url("../images/svg/strzalka-w-prawo.svg");
  background-position: center;
  background-repeat: no-repeat;
  content: '';
  position: relative;
  left: 70px;
  width: 29px;
  display: inline-block;
  height: 23px;
  margin-left: -30px;
}

.EtapyRealizacji__icons .icoBox .imgico::after::last-child {
  margin: unset;
  width: 0;
  height: 0;
  background: unset;
}

@media (max-width: 768px) {
  .EtapyRealizacji__icons .icoBox .imgico::after {
    display: none;
  }
}

.EtapyRealizacji__icons .icoBox .imgico:last-child::after {
  display: none;
}

.EtapyRealizacji__icons .icoBox img {
  margin-bottom: 1.5rem;
}

.EtapyRealizacji__icons .icoBox p {
  font-size: 12px;
  color: #231F20;
}

@media (max-width: 480px) {
  .EtapyRealizacji__icons .icoBox p {
    font-size: 10px;
  }
}

.EtapyRealizacji .bg {
  margin-left: -10.8rem;
}

.EtapyRealizacji .titleSection {
  margin-top: -1.7rem;
  padding-bottom: 2.5rem;
}

.EtapyRealizacji .subtitleSection {
  margin-left: -.6rem;
}

ul#menu-menu-glowne-pl {
  clear: both;
  margin-right: 0;
}

ul#menu-menu-glowne-pl li {
  padding-left: 10px;
}

ul#menu-menu-glowne-pl a {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 300;
}

@media (max-width: 991px) {
  ul#menu-menu-glowne-pl a {
    font-size: 12px;
  }
}

@media (min-width: 1024px) {
  ul#menu-menu-glowne-pl a {
    font-size: 13px;
  }
}

@media (min-width: 992px) {
  ul#menu-menu-glowne-pl a:hover {
    -webkit-animation-name: hvr-pulse;
    animation-name: hvr-pulse;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
}

ul#menu-menu-glowne-pl.navbar-nav {
  align-items: center;
}

.navbar-collapse {
  padding: 10px 0;
}

.navbar-collapse .nav-item:nth-child(1) {
  margin-right: 10px;
}

.navbar-collapse .nav-item:nth-child(2) {
  margin-right: 10px;
}

.navbar-collapse .nav-item:nth-child(3) {
  margin-right: 10px;
}

.navbar-collapse .nav-item:nth-child(4) {
  margin-right: 10px;
}

.navbar-collapse .nav-item:nth-child(5) {
  margin-right: 20px;
}

.navbar-collapse .nav-item.dropdown > a {
  padding-right: 27px !important;
  background: url("../images/svg/downArrow.svg") no-repeat top 16px right 10px;
  background-size: 12px;
}

.navbar-collapse .nav-item .nav-link {
  color: #231F20;
  margin: 2px 5px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: .3px;
  display: flex;
  align-items: center;
  padding: 10px 5px 5px 0;
}

@media (min-width: 1100px) {
  .navbar-collapse .nav-item .nav-link {
    padding: 9px 5px 5px 0 !important;
  }
}

@media (max-width: 768px) {
  .navbar-collapse .nav-item .nav-link {
    color: #111110;
    margin: 0px 0px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .3px;
    display: flex;
    align-items: center;
    padding: 5px 0;
  }
}

.navbar-collapse .nav-item .nav-link:hover {
  text-decoration: underline;
}

.navbar-collapse .nav-item.active > .hvr-border-fade {
  box-shadow: inset 0 0 0 1px #fff, 0 0 1px rgba(0, 0, 0, 0);
}

.navbar-collapse .dropdown:hover {
  -webkit-animation-name: unset;
  animation-name: unset;
  -webkit-animation-duration: unset;
  animation-duration: unset;
  -webkit-animation-timing-function: unset;
  animation-timing-function: unset;
  -webkit-animation-iteration-count: unset;
  animation-iteration-count: unset;
}

@media (min-width: 992px) {
  .navbar-collapse .dropdown:hover .dropdown-menu {
    display: block !important;
  }
}

.navbar-collapse .dropdown .dropdown-menu {
  background-color: transparent;
  border: 0 !important;
  border-radius: 5px;
  min-width: 250px;
}

@media (max-width: 992px) {
  .navbar-collapse .dropdown .dropdown-menu {
    padding-top: 0;
    padding-left: 7px;
  }
}

.navbar-collapse .dropdown .dropdown-menu .nav-link {
  border-bottom: 1px solid #231F20 !important;
  transition: .2s;
}

.navbar-collapse .dropdown .dropdown-menu .nav-link.hvr-border-fade {
  border: 0 !important;
  border-bottom: 1px solid #231F20 !important;
}

.navbar-collapse .dropdown .dropdown-menu .nav-link.hvr-border-fade:hover {
  border: 0 !important;
  border-bottom: 1px solid #666 !important;
  box-shadow: none !important;
}

section {
  padding-top: 4rem;
  padding-bottom: 4rem;
  overflow: hidden;
}

@media (max-width: 480px) {
  section {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.Dekatin {
  padding-bottom: .9rem;
}

.Dekatin__img {
  text-align: center;
}

@media (max-width: 1260px) {
  .Dekatin__img {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.Dekatin__container {
  max-width: 1280px;
  margin: 0 auto;
  background-color: #231F20;
  box-shadow: 0px 0px 20px #000000B3;
}

@media (min-width: 1050px) and (max-width: 1279px) {
  .Dekatin__container {
    max-width: 1050px;
  }
}

@media (max-width: 992px) and (max-width: 1049px) {
  .Dekatin__container {
    max-width: 940px;
  }
}

.Dekatin__textBox {
  padding: 2rem 3rem;
}

.Dekatin__textBox .bg {
  margin-left: -.6rem;
}

.Dekatin__textBox .titleSection {
  font-size: 40px;
  color: #fff;
  font-weight: 700;
  margin-top: -1.7rem;
  padding-bottom: 1rem;
}

@media (max-width: 1049px) {
  .Dekatin__textBox .titleSection {
    font-size: 34px;
  }
}

@media (max-width: 500px) {
  .Dekatin__textBox .titleSection {
    font-size: 30px;
  }
}

.Dekatin__textBox .text {
  color: #fff;
  font-size: 14px;
  line-height: 26px;
  font-weight: 300;
}

.Dekatin .btn {
  margin: 1rem 0 0 0;
  top: 0;
  transform: none;
  border-color: #FFFFFF !important;
  background: unset;
}

.Dekatin .btn a {
  color: #ffffff;
}

.Dekatin .btn a:hover {
  color: #231F20 !important;
  text-decoration: none;
}

.Dekatin .btn:hover {
  background-color: #ffffff;
  border-color: #FFFFFF !important;
}

.MainPowtarzalne {
  padding-bottom: 0;
}

.MainPowtarzalne__textBox {
  padding: 2rem 4rem;
}

.MainPowtarzalne__textBox .bg {
  margin-left: -.6rem;
}

.MainPowtarzalne__textBox .titleSection {
  margin-bottom: 2rem;
  padding-bottom: 0;
  margin-left: -.6rem;
  margin-top: -1.7rem;
}

.MainPowtarzalne__textBox .subtitleSection {
  margin-left: -.6rem;
}

.MainPowtarzalne__textBox .text {
  line-height: 27px;
}

.MainPowtarzalne .btn {
  margin: 1rem 0 0 0;
  top: 0;
  transform: none;
}

.MainPowtarzalne .btn a {
  color: #231f20;
}

.MainPowtarzalne .btn a:hover {
  color: #FFFFFF !important;
  text-decoration: none;
}

@media (max-width: 480px) {
  .MainPowtarzalne {
    padding: 0 15px;
  }
}

.MainPowtarzalne__img {
  text-align: center;
}

@media (max-width: 480px) {
  .MainPowtarzalne__img {
    display: none;
  }
}

.MaszPytania {
  padding: 2rem 3rem;
}

.MaszPytania .bg {
  margin-left: -10.8rem;
}

.MaszPytania .titleSection {
  margin-top: -1.7rem;
  padding-bottom: 1.5rem;
}

.MaszPytania .subtitleSection {
  margin-left: -.6rem;
}

.MaszPytania .txt {
  color: #111110;
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  text-align: center;
}

.MaszPytania__form {
  margin-top: 3rem;
}

.MaszPytania .txtAkceptacja {
  color: #727272;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  padding-left: 10px;
}

.MaszPytania .wpcf7-form-control.wpcf7-acceptance .wpcf7-list-item {
  margin-left: 0;
}

.MaszPytania .wpcf7-form-control.wpcf7-textarea {
  max-height: 200px;
}

.MaszPytania .btnBox.wyslij {
  text-align: center;
  margin-top: 3rem;
}

.MaszPytania .form-group.agree {
  text-align: left;
  margin-top: 2rem;
}

.pageStandard .titlePage {
  padding-bottom: 2rem;
  color: #000000;
  line-height: 2.3rem;
  font-weight: 700;
}

@media (min-width: 1024px) {
  .pageStandard .titlePage {
    font-size: 30px;
    line-height: 28px;
    text-align: center;
  }
}

@media (min-width: 1024px) {
  .pageStandard.mainContainer {
    padding: 0 15px;
  }
}
