.NaszeRealizacje{
    padding-bottom: .9rem;
    background-color: #231F20;
    box-shadow: 0px 0px 20px #000000B3;

    &__container{
        max-width: 1280px;
        margin: 0 auto;
        a{
            color:#ffffff;
            &:hover{
                text-decoration: underline;
                color: #fff
            }
        }
    }
    
    &__textBox{
        padding: 0 3rem 2rem;
        .bg{
            margin-left: -.6rem;
        }
        .titleSection{
            font-size: 40px;
            color: #fff;
            font-weight: 700;
            margin-top: -1.7rem;
            padding-bottom: 1rem;
            @include rwd(large-phone) {
                font-size: 30px;
            }
        }
        .subtitleSection{
            color: #fff;
        }
        .text{
            color: #fff;
            font-size: 14px;
            line-height: 26px;
            font-weight: 300;
            .modal {
                color: #212529
            }
        }
    }
    .btn{
        margin: 1rem 0 0 0;
        top: 0;
        transform: none;
        border-color: $cWhite!important;
        background: unset;
        a{
            color: #ffffff;
            &:hover{
                color: #231F20!important;
                text-decoration: none;
            }
        }
        &:hover{
            background-color: #ffffff;
            border-color: $cWhite!important;
        }
    }
    .SliderRealizacje{
        .item{
            width: 100%;
            height: 400px;
            background-repeat: no-repeat!important;
            background-size: cover!important;
            background-position-x: center!important;
            background-position-y: center!important;
            .textBox {
                position: absolute;
                bottom: .7rem;
                left: .7rem;
                .secondText {
                    color: #fff;
                    font-size: 12px;
                    font-weight: 400;
                }
                .mainText {
                    color: #fff;
                    font-weight: 900;
                    font-size: 22px;
                }
            }
        }
    }
}
