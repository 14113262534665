.cta{
    &__baner{
        $root: &;
        padding: 3rem 0;
        margin-bottom: 5rem;
        margin-top: 5rem;
        background: url('../../dist/images/tlo_pod_baner.png');
        &--white{
            background: none;
            &__left{            
                color: $cTitle;
                &__subtitle{
                    color: $cTitle;
                }
            }
        }
        background-repeat: no-repeat;
        @include rwd(large-phone) {
            background-position-x: 50%;
            margin: 2rem 0 0 0;
        }
        &__left{            
            color: $cWhite;
            &__title{
                font-size: 40px;
                font-weight: 700;
                text-align: center;
                text-transform: uppercase;
                @include rwd(large-phone) {
                    font-size: 26px;
                }
            }
            &__subtitle{
                font-size: 20px;
                span {
                    font-size: 40px;
                    font-weight: 700;
                }
                font-weight: 300;
                text-align: center;
                text-transform: uppercase;
                @include rwd(large-phone) {
                    font-size: 16px;
                }
            }
        }
    }
    &__button{
        &--green{
            display: block;            
            width: 299px;
            height: 55px;
            border: 1px solid #146505;
            border-radius: 30px;
            background-color: #ffffff;
            margin: 0 auto;
            @include vertical-center();
        }
        a{
            color: $cGreen;
            font-size: 20px;
            font-weight: 500;
            text-transform: uppercase;
            &:hover{
                color: $cWhite!important;
                text-decoration: none;
            }
        }
    }
}