.EtapyRealizacji{
    margin-top: 0rem;
    @include rwd(large-phone) {
        margin: 0;
    }
    background-color: #fff;
    &__icons{
        display: flex;
        align-items: baseline;
        -webkit-justify-content: space-around;
        justify-content: space-around;
        -webkit-flex-flow: row wrap;
        flex-flow: row wrap;

        .icoBox{
            text-align: center;
            width: 20%;
            @include rwd(large-phone) {
                width: 100%;
            }
            padding: 0 10px;
            @include rwd(large-phone) {
                margin: 0 1rem 2rem;
            }
            .txt{
                color: #231F20;
                font-size: 16px;
                line-height: 44px;
                font-weight: 700;
            }
            .txtSmall{
                color: #727272;
                font-size: 14px;
            }
            .imgico{                
                &::after{
                    background: url('../images/svg/strzalka-w-prawo.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    content: '';
                    position: relative;
                    left: 70px;
                    width: 29px;
                    display: inline-block;
                    height: 23px;
                    margin-left: -30px;
                    &::last-child{
                        margin: unset;
                        width: 0;
                        height: 0;
                        background: unset;                
                    }
                    @media(max-width:768px){
                        display: none;
                    }
                }
                &:last-child::after{
                    display: none;
                }
            }
            img{
                margin-bottom: 1.5rem;
            }
            p{
                font-size: 12px;
                color: #231F20;
                @include rwd(large-phone) {
                    font-size: 10px;
                }
            }
        }
    }

    .bg{
        margin-left: -10.8rem;
    }
    .titleSection{
        margin-top: -1.7rem;
        padding-bottom: 2.5rem;
    }
    .subtitleSection{
        margin-left: -.6rem;
    }
}