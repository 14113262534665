.pageStandard{
    .titlePage{        
        padding-bottom: 2rem;
        color: $cBlack;
        line-height: 2.3rem;
        font-weight: 700;
        @include rwd(large-tablet) {
            font-size: 30px;
            line-height: 28px;
            text-align: center;
        }
    }
    &.mainContainer{
        @include rwd(large-tablet) {
            padding: 0 15px;
        }
    }
}