body{
    color: $cBody;
}
.mainContainer{
    max-width: 1122px;
    margin:0 auto;

    @media (min-width:992px) and (max-width:1279px){
        padding-left:15px;
        padding-right:15px;
    }

    @media (min-width:1200px) and (max-width:1500px){
        padding-left:20px;
        padding-right:20px;
    }
}
.container-fluid {
    
    @include rwd(large-tablet,true) {
        padding-right: unset;
        padding-left: unset;
    }
}
a{
    color: $cBody;
}

/* Pulse */
@-webkit-keyframes hvr-pulse {
    25% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    75% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
  @keyframes hvr-pulse {
    25% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    75% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
/* BACKGROUND */



/* COLORS */


/*  others */


p{
    font-size:14px;
    @media(max-width:1279px){
        font-size:12px;
    }
}


.slide{
    width:100%;
    height:100%;
    position:relative;
}

.psuedo-background-img{
        background-color: rgba(0,0,0,.8);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    @include rwd(large-phone) {
        background-color: rgba(0,0,0,.8);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.titleSection{
    padding-bottom: 2rem;
    color: $cTitle;
    line-height: 44px;
    font-size:40px;
    text-transform: uppercase;  
    @include FontBold(700);

    @include rwd(large-laptop) {
        font-size:40px;
    }
    @include rwd(laptop) {
        font-size:40px;
    }
    @include rwd(large-tablet) {
        font-size:40px;
    }
    @include rwd(medium-tablet) {
        font-size:32px;
    }
    @include rwd(tablet) {
        font-size:32px;
    }
    @include rwd(small-tablet) {
        font-size:32px;
    }
    @include rwd(large-phone) {
        font-size:30px;
    }
    @include rwd(phone) {
        font-size:28px;
    }

    strong,
    &--bold{
        @include FontBold(500);
    }

    &--small{
        font-size:26px;
        @include rwd(large-laptop) {
            font-size:26px;
        }
        @include rwd(laptop-h) {
            font-size:26px;
        }
        @include rwd(laptop) {
            font-size:22px;
        }
        @include rwd(large-tablet) {
            font-size:22px;
        }
        @include rwd(medium-tablet) {
            font-size:22px;
        }
        @include rwd(tablet) {
            font-size:22px;
        }
        @include rwd(small-tablet) {
            font-size:20px;
        }
        @include rwd(large-phone) {
            font-size:18px;
        }
        @include rwd(phone) {
            font-size:16px;
        }

    }

    &--xsmall{
        font-size:20px;        
        @include rwd(large-phone) {
            font-size:18px;
        }
        @include rwd(phone) {
            font-size:16px;
        } 
    }

}

.subtitleSection{
    padding-bottom: 0;
    color: $cSubtitle;
    font-size: 22px;
    font-weight: 400;
    line-height: 27px; 

    @include rwd(large-laptop) {
        font-size:22px;
    }
    @include rwd(laptop-h) {
        font-size:22px;
    }
    @include rwd(laptop) {
        font-size:20px;
    }
    @include rwd(large-tablet) {
        font-size:20px;
    }
    @include rwd(medium-tablet) {
        font-size:20px;
    }
    @include rwd(tablet) {
        font-size:20px;
    }
    @include rwd(small-tablet) {
        font-size:20px;
    }
    @include rwd(large-phone) {
        font-size:18px;
    }
    @include rwd(phone) {
        font-size:18px;
    }

    strong,
    &--bold{
        @include FontBold(500);
    }

    &--small{
        font-size:22px;
        @include rwd(large-laptop) {
            font-size:22px;
        }
        @include rwd(laptop-h) {
            font-size:22px;
        }
        @include rwd(laptop) {
            font-size:20px;
        }
        @include rwd(large-tablet) {
            font-size:20px;
        }
        @include rwd(medium-tablet) {
            font-size:20px;
        }
        @include rwd(tablet) {
            font-size:20px;
        }
        @include rwd(small-tablet) {
            font-size:20px;
        }
        @include rwd(large-phone) {
            font-size:18px;
        }
        @include rwd(phone) {
            font-size:16px;
        }
    }

    &--xsmall{
        font-size:16px;
        @include rwd(large-phone) {
            font-size:14px;
        }
        @include rwd(phone) {
            font-size:12px;
        }
 
    }

}

.novisible{
    display: none!important;
}
.showvisible{
    display: block!important;
    padding: 0 !important;
}
.centerOfScreen{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); /* Yep! */
        width: 48%;
        height: 59%;
}
ul {
    list-style: url('../../dist/images/svg/ptaszek_ok.svg');
    font-size: 14px;
    margin: 0 2rem;
    li {
        margin: .5rem 0;
        padding-left: .4rem;
    }
}
.icoTxt {
    color: $cGreen;
    font-size: 12px;
}
