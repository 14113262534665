
.header{
    .navbar{
        @media(max-width:992px){
            background: #000;
            margin-bottom: 11rem;
        }        
    }
    $root: &;
    
    margin: 0 auto -186px auto;
    z-index: 2;
    position: relative;
    &__menu{        
        ul {
            li{
                @include rwd(tablet) {
                    margin: .2rem 0;
                    padding-left: .4rem;
                }
            }
        }
        &__top{
            display: flex;
            color: $cGrey2;
            float: right;
        }
    }
    &__icoPhone,&__icoMail{
        @include rwd(tablet) {
            display: none;
        }
        margin: 0 1rem;
        a.ico{
            color: $cGrey2;
            font-size: 16px;
            font-weight: 600;
            }
        img.ico{
            margin-right: .8rem;
            width: 25px;
            @include rwd(tablet) {
                width: 25px;
            }
        }
        &.nofixed{
            display: block;
        }
        &.fixed{
            display: none;
        }
    }
    
    &__icoPhone__mobile{
        @include rwd(medium-tablet, true) {
            display: none;
        }
        a.ico{
            color: $cGrey2;
            font-size: 16px;
            font-weight: 600;
            }
        img.ico{
            margin-right: .8rem;
            width: 25px;
        }
    }
        
    &__logo{
        width: 160px;
        height: auto;
        @media(max-width:992px){
            width: 140px;
            height: auto;
        }
        @media(max-width:768px){
            width: 110px;
            height: auto;
        }
        @include rwd(large-phone) {
            width: 80px;
            height: auto;
        }
        @include rwd(phone) {
            width: 70px;
            height: auto;
        }
    }

    #navbarNavDropdown {   
        justify-content: center;
        flex-direction: row-reverse;
    }
    
    //// HEADER FIXED 

    &--fixed{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        background-color: #000;
        width: 100%;
        max-width: unset;
        #navbarNavDropdown {   
            flex-direction: unset; 
        }
        .header__logo{
            height: 35px;
            width:auto;
        }
        #{$root}__icoPhone{
            &.nofixed{
                display: none;
                @include rwd(tablet) {
                    display: none!important;
                }
            }
            &.fixed{
                display: block;
            }
        }
        
        #{$root}__icoPhone,
        #{$root}__icoMail{
            margin: 0;
            a.ico{
                color: #bcbcbc;
                font-size: 12px;
                font-weight: 500;
                margin-left: 2rem;
            }
            img.ico{
                margin-right: .8rem;
                height: 25px;
            }            
        }

        ul#menu-main-manu a {
            color: #339a99;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 400;
        }

        .header__calltoUs .svg img {
            width: 20px;
            height: 20px;
        }
        .header__calltoUs a {
            color: #339a99;
            font-size: 22px;
            margin-left: .4rem;
        }
        .navbar-brand{
        }

        .navbar{
            padding-top:0;
            padding-bottom:0;
            width: 100%;
            margin: 0 auto;
            justify-content: center;
            text-align: center;
            max-width: 1122px;
            @include rwd(tablet) {
                margin: 0 auto;
                padding: 10px 10% 0;
            }
            @include rwd(large-phone) {
                background: #000;
            }
            
        }

        .dropdown-menu{
            margin-top:0 !important;   
        }

    }
    //// END HEADER FIXED 
}