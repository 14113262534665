.MaszPytania{
    padding: 2rem 3rem;
    .bg{
        margin-left: -10.8rem;
    }
    .titleSection{
        margin-top: -1.7rem;
        padding-bottom: 1.5rem;
    }
    .subtitleSection{
        margin-left: -.6rem;
    }
    .txt{
        color: #111110;
        font-size: 16px;
        line-height: 20px;
        font-weight: 300;
        text-align: center;
    }
    &__form{
        margin-top: 3rem;
    }
    .txtAkceptacja{
        color: #727272;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        padding-left: 10px;
    }
    .wpcf7-form-control.wpcf7-acceptance .wpcf7-list-item{
        margin-left: 0;
    }
    .wpcf7-form-control.wpcf7-textarea{
        max-height: 200px;
    }
    .btnBox.wyslij{
        text-align: center;
        margin-top: 3rem;
    }
    .form-group.agree{
        text-align: left;
        margin-top: 2rem;
    }
}
