.Dekatin{
    padding-bottom: .9rem;
    &__img {
        text-align: center;
        @media(max-width:1260px){
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
    &__container{
        max-width: 1280px;
        margin: 0 auto;
        background-color: #231F20;
        box-shadow: 0px 0px 20px #000000B3;
        @media (min-width:1050px) and (max-width:1279px){
            max-width: 1050px;
        }
    
        @media (max-width:992px) and (max-width:1049px){
            max-width: 940px;
        }
    }
    
    &__textBox{
        padding: 2rem 3rem;
        .bg{
            margin-left: -.6rem;
        }
        .titleSection{
            font-size: 40px;
            color: #fff;
            font-weight: 700;
            margin-top: -1.7rem;
            padding-bottom: 1rem;
            @media(max-width:1049px){
                font-size: 34px;
            }
            @media(max-width:500px){
                font-size: 30px;
            }
        }
        .text{
            color: #fff;
            font-size: 14px;
            line-height: 26px;
            font-weight: 300;
        }
    }
    .btn{
        margin: 1rem 0 0 0;
        top: 0;
        transform: none;
        border-color: $cWhite!important;
        background: unset;
        a{
            color: #ffffff;
            &:hover{
                color: #231F20!important;
                text-decoration: none;
            }
        }
        &:hover{
            background-color: #ffffff;
            border-color: $cWhite!important;
        }
    }
}

.MainPowtarzalne{
    padding-bottom: 0;
    &__textBox{
        padding: 2rem 4rem;
        .bg{
            margin-left: -.6rem;
        }
        .titleSection{
            margin-bottom: 2rem;
            padding-bottom: 0;
            margin-left: -.6rem;
            margin-top: -1.7rem;
        }
        .subtitleSection{
            margin-left: -.6rem;
        }
        .text{
            line-height: 27px;
        }
    }
    .btn{
        margin: 1rem 0 0 0;
        top: 0;
        transform: none;
        a{
            color: #231f20;
            &:hover{
                color: $cWhite!important;
                text-decoration: none;
            }
        }
    }
    @include rwd(large-phone) {
        padding: 0 15px;
    }
    &__img{
        text-align: center;
        @include rwd(large-phone) {
            display: none;
        }
    }
}
